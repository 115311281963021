import "./App.css";
import { Routes, Route } from "react-router-dom";
import Landing from "./Screens/Landing/Landing.jsx";
import "./Components/Loading/loading.css";
import { useState, useEffect } from "react";
import LoadingScreen from "./Components/Loading/Loading.jsx";
import AboutUs from "./Screens/AboutUs/AboutUs";
import Menu from "./Screens/Menus/Menu.jsx";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Hide loading screen after 3 seconds
    }, 3000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <LoadingScreen />
      ) : (
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/menu" element={<Menu />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
