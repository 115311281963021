import React, { useState, useEffect } from "react";
import "./loading.css";
import logo from "../../Assets/logo.png";

const LoadingScreen = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    loading && (
      <div className="loading-screen">
        <img src={logo} className="logo-loading" />
        <h4 className="yourcozycorner">Your Cozy Corner in Hoboken</h4>
        {/* <div className="loader"></div> */}
      </div>
    )
  );
};

export default LoadingScreen;
