import React from "react";
import "./navigator.css";
import { Link } from "react-router-dom";

function Navigator({ scrollToFooter }) {
  return (
    <div className="navigator">
      <Link to="/menu">MENU</Link>
      <Link to="/aboutus">ABOUT US</Link>
      <button onClick={scrollToFooter} className="more-button">
        MORE
      </button>
    </div>
  );
}

export default Navigator;
