import React, { useEffect } from "react";
import "./aboutus.css";
import ProgressBar from "../../Components/ProgressBar/ProgressBar.jsx";
import Header from "../../Components/Header/Header.jsx";
import cafevistaout from "../../Assets/cafevistaout.jpeg";
import img2 from "../../Assets/swiper2.jpg";
import Footer from "../../Components/Footer/Footer.jsx";
import { Link } from "react-router-dom";

function AboutUs() {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-scroll");
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.9) {
          el.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Link to="/">
        <Header />
      </Link>
      <ProgressBar
        sections={["section1", "section2", "section3", "section4", "section5"]}
      />
      <div className="sections">
        <section id="section1">
          <h4 className="section-header fade-scroll">DISCOVER CAFE VISTA</h4>
          <p className="section-p fade-scroll">
            Tucked away in the heart of the city, Cafe Vista is a hidden gem
            established in 2019 and proudly opened its doors in June 2020,
            amidst the challenges of the pandemic. Despite the obstacles, this
            charming cafe quickly won the hearts of local customers and became a
            highly sought-after destination.
          </p>
          <img
            src={cafevistaout}
            alt="Cafe Vista from Outside"
            className="section-img fade-scroll"
          />
        </section>
        <section id="section2">
          <h4 className="section-header fade-scroll">A VISIONARY’S DREAM</h4>
          <p className="section-p fade-scroll">
            Cafe Vista is a women-owned and operated cafe, founded by the
            visionary Balsayat Jorayeva. Located at 527 Monroe Street, this
            beautifully designed and curated haven reflects Balsayat's passion
            and dedication. Every detail, from the carefully selected decor to
            the delicious menu offerings, has been lovingly chosen by Balsayat
            and her team Cafe Vista is a testament to her commitment to creating
            a warm and inviting atmosphere.
          </p>
          <img
            src="https://i.imgur.com/BrszU78.jpeg"
            alt="Owner"
            className="section-img fade-scroll"
          />
        </section>
        <section id="section3">
          <h4 className="section-header fade-scroll">WHAT WE OFFER</h4>
          <p className="section-p fade-scroll">
            Indulge in our extensive coffee menu, featuring high-quality beans.
            Our selection is carefully crafted to satisfy every coffee lover's
            palate. Complement your coffee with our premium tea offerings,
            sourced from the finest tea leaves.
          </p>
          <img src={img2} alt="coffee" className="section-img-2 fade-scroll" />
        </section>
        <section id="section4">
          <h4 className="section-header fade-scroll">BREAKFAST FAVORITES</h4>
          <p className="section-p fade-scroll">
            Start your day delightfully with our freshly prepared breakfast
            options:
            <ul>
              <li>Omeletes</li>
              <li>Bagels</li>
              <li>Sandwiches</li>
              <li>Avocado Toasts and many more...</li>
            </ul>
            Treat yourself to our mouthwatering baked goods, crafted with love
            and care.
          </p>
          <img
            src="https://i.imgur.com/6jMjNI7.jpeg"
            alt="Avocado Toast"
            className="section-img fade-scroll"
          />
        </section>
        <section id="section5">
          <h4 className="section-header fade-scroll">CAFE VISTA EXPANDS</h4>
          <p className="section-p fade-scroll">
            After initial success, Cafe Vista opened its second location, Dream
            Vista, in Jersey City. We're proud to serve the local community and
            envision a beloved gathering place. Founder Balsayat aims to expand
            further through dedication and excellence.
          </p>
          <img
            src="https://i.imgur.com/W0Kbmic.jpeg"
            alt="dread,,"
            className="section-img fade-scroll"
          />
        </section>
      </div>
      <div className="footer-box">
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
