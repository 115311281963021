import "./progressbar.css";
import React, { useEffect, useState } from "react";

const ProgressBar = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(0);

  const handleScroll = () => {
    const offsets = sections.map(
      (id) => document.getElementById(id).getBoundingClientRect().top
    );
    const currentSection = offsets.findIndex((offset) => offset >= 0);
    setActiveSection(
      currentSection === -1 ? sections.length - 1 : currentSection
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sections]);

  return (
    <div className="progress-bar">
      {sections.map((_, index) => (
        <div
          key={index}
          className={`progress-dot ${index === activeSection ? "active" : ""}`}
          style={{ top: `${index * 140}px` }}
        />
      ))}
    </div>
  );
};

export default ProgressBar;
