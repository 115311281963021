import React from 'react'
import "./header.css"
import logo from "../../Assets/logo2.PNG"

function Header() {
  return (
    <div className='heaeder'>
        <img src={logo} alt='logo' className='logo-for-header'/>
    </div>
  )
}

export default Header