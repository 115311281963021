import React, { useEffect, useRef } from "react";
import "./swipermanual.css";
import { Swiper } from "swiper/bundle";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-fade";
import img1 from "../../Assets/avotoast.JPG";
import img2 from "../../Assets/swiper2.jpg";

import { EffectFade } from "swiper/modules";

function SwiperGallery() {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        loop: true,
        effect: "fade",
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        modules: { EffectFade },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
  }, []);

  return (
    <div>
      <div className="swiper" ref={swiperRef}>
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img
              src={img1}
              className="swiper-photos"
              alt="Feta Avotoast with coffee"
            />
            <h2 className="photo-text">BREAKFAST & COFFEE</h2>
          </div>
          <div className="swiper-slide">
            <img src={img2} className="swiper-photos" alt="Hoboken" />
            <h2 className="photo-text2">
              SERVING HOBOKEN <br /> SINCE 2020
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwiperGallery;
